
.login-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
    background-color: rgba(255, 255, 255, 0.5);
}
.background-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    opacity: 0.6;
    height: 100vh;
    z-index: 1;
}
.login-button {
  width:100%;
}
.login-container {
  position: relative;
  border-radius: 10px;
  text-align: left;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 10px 20px;
  min-width: 600px;
  justify-content: space-between;
}
.login-form-container {
  min-width: 400px;
}
.top-left-logo {
  opacity: 1;
  position: absolute;
  width: 100%;
  padding-top: 10px;
  z-index: 10;
  max-height: 50%;
  cursor: pointer;
}
.bottom-left-logo {
  opacity: 1;
  position: absolute;
  width: 100%;
  bottom: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  z-index: 10;
  cursor: pointer;
}
.bottom-right-logo {
  opacity: 1;
  position: absolute;
  width: 100%;
  bottom: 10px;
  padding-right: 10px;
  cursor: pointer;
  z-index: 10;
}
.top-right-contacts {
  position: relative;
  top: 50px;
  z-index: 10;
}
.login-link {
  color: blue;
}
